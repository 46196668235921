
.cash-out {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(var(--nav-height) + 2.5rem);
    right: 2rem;
}

.cash-out button.normal {
    background-color: var(--main);
    color: var(--white);
    font-family: Paytone One, sans-serif;
    padding: 0.5rem 0.75rem;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
}

.cash-out button.normal:hover {
    background-color: var(--light);
}

.cash-out button.disabled {
    background-color: var(--dark);
    color: var(--white);
    font-family: Paytone One, sans-serif;
    padding: 0.5rem 0.75rem;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: not-allowed;
}