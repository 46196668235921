
.home {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-content-section-with-image {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.home-content-section-with-image p {
    padding-bottom: 1rem;
    border-bottom: var(--main) 2px solid;
    text-align: justify;
}


.home-content-section-with-image h2 {
    color: var(--main);
}

.home-content-section-with-image-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-grow: 1;
    width: 100%;
}

.home-content-section-with-image-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-grow: 1;
    width: 100%;
}

#home-img-1 {
    width: 10rem;
    height: 10rem;
}

.content-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;
    padding: 0 0 2rem 0;
}

.content-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;  
}

.content-inner.background {
    background-image: url('/src/assets/splashart_2.jpg');
    background-size: cover;
    background-position: 50% 0%;
    background-repeat: no-repeat;
    padding-top: 2rem;
    width: 100%;
}

.content-item.background {
    background-image: url('/src/assets/splashart_6.jpg');
    margin-top: -5rem;
    padding-top: 5rem;
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.content-inner.fullwidth {
    padding: 0;
    gap: 0;
    width: 100%;
}


.content-shape {
    display: block;
    width: 100%;
    height: 90px;
    backface-visibility: hidden;
}

.content-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.content-item.fullwidth {
    width: 100%;
    margin-top: -5rem;
    background-image: url('/src/assets/splashart_4.jpg');
    background-size: cover;
    background-position: 50% 0%;
    background-repeat: no-repeat;
}

.content-item.gray {
    background-color: var(--background-layer-1);
    padding: 2rem 0;
    width: 100%;
}

.content-container {
    display: flex;
    flex-wrap: wrap; /* Allow content boxes to wrap */
    flex-direction: row;
    align-items: stretch; /* Align items to stretch vertically */
    justify-content: center;
    gap: 1rem;
    padding: 0 15rem;
}

.content-container .content-box {
    flex: 1; /* Make content boxes the same size */
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-width: 20rem;
    max-width: 30rem;
    gap: 1rem;
    background-color: rgba(30, 35, 45, 0.925);
    padding: 1rem 2rem;
    border-radius: var(--border-radius-3);
    transition: all 0.2s ease-in-out;
}

.content-container .content-box.special-case {
    background-color: var(--background-layer-2);
}

.content-box.icon, .content-box {
    box-shadow: 0 0 5px 0.2rem rgba(0,0,0,0.2);
}

.content-box.icon h3 {
    text-align: center;

}

.content-container .content-box.active {
    opacity: 1;
    display: flex;
}

.content-container .content-box.inactive {
    opacity: 0;
    display: none;
}

/* Example animation for transitioning between active and inactive states */
@keyframes fadeInOut {
    0% {
        opacity: 0;
        display: block;
    }
    50% {
        opacity: 0.75;
    }
    100% {
        opacity: 1;
    }
}


.content-container .content-box .content-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 25rem;
    background-color: transparent;
}

.content-separator {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.content-container p {
    text-align: left;
    
}

.content-container h2 {
    text-align: center;
}

.content-icon svg {
    width: 5rem;
    height: 5rem;
    color: var(--main);
}

.content-container.title {
    flex-direction: column;
    align-self: stretch;
    align-items: center;
    text-align: center;
    gap: 0;
}

.content-container.title h2 {
    text-align: center;
    color: var(--main);
}

.content-container.title.fill h2{
    width:fit-content;
    padding: 0.25rem 0.5rem;
    border-top-left-radius:0.4rem;
    border-top-right-radius:0.4rem;
    background-color: rgba(30, 35, 45, 0.9);
}

.content-container.title.fill h3{
    width:fit-content;
    padding: 0.25rem 0.5rem;
    border-radius:0.4rem;
    background-color: rgba(30, 35, 45, 0.9);
}


.content-container.title.fill-special h2{
    padding: 0.25rem 0.5rem;
    border-radius: 0.4rem;
    background-color: rgba(30, 35, 45, 0.9);
}

.content-container.title.fill-special h3{
    width:fit-content;
    padding: 0.25rem 0.5rem;
    border-bottom-right-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
    background-color: rgba(30, 35, 45, 0.9);
}

.content-item .blue-line {
    width: 100%;
    height: 2rem;
    background-color: var(--dark);
    margin-top: 6rem;
    box-shadow: inset 0 0 1rem 0.2rem rgba(0,0,0,0.2);
}
.left {
    justify-content: left;
}

.content-container.title.left {
    align-items: flex-start;
}

.content-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.content-selector button {
    width: 100%;
    padding: 0.85rem;
    border-radius: var(--border-radius-3);
    border: none;
    color: var(--main);
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    border-bottom: 3px solid transparent;
    transition: all 0.2s ease-in-out;
    position: relative; /* Add position relative for absolute positioning */
}

/* Animation for filling up the border-bottom */
@keyframes fillBorder {
    from {
        width: 0; /* Start with no width */
    }
    to {
        width: 100%; /* Fill up to full width */
    }
}

.content-selector button.active {
    color: var(--main);
}

.content-selector button.inactive {
    background-color: rgba(30, 35, 45, 0.925);
    color: var(--text);
}

.content-selector button.inactive:hover {
    color: var(--main);
}

.content-selector button.active::after {
    content: ''; /* Create pseudo-element */
    position: absolute;
    bottom: -3px; /* Position below the border */
    left: 0; /* Start from left */
    width: 0; /* Initially no width */
    height: 3px; /* Height of border */
    background-color: var(--main); /* Color of border */
    border-radius: var(--border-radius-3); /* Same border-radius as button */
    animation: fillBorder 5s linear forwards; /* Apply animation */
}

@media screen and (max-width: 1600px) {
    .content-container {
        padding: 0 8rem;
    }
}

@media screen and (max-width: 1300px) {
    .content-container {
        padding: 0 5rem;
    }
}

@media screen and (max-width: 1200px) {
    .content-container {
        padding: 0 3rem;
    }
}

@media screen and (max-width: 1000px) {
    .content-container {
        padding: 0 2rem;
    }
}

@media screen and (max-width: 800px) {
    .content-container {
        padding: 0 1rem;
    }
    .content-selector {
        display: none;
    }
    .content-container .content-box {
        height: 20rem;
    }
    .content-container .content-box.icon {
        height: auto;
    }
}

@media screen and (max-width: 699px) {
    .content-icon svg {
        width: 3.5rem;
    }
    .content-container.title h1 {
        font-size: 1.75rem;
    }
    .content-container.title h3 {
        font-size: 1.2rem;
    }
    .content-subtitle h3 {
        font-size: 1.2rem;
    }

    .content-subtitle h2 {
        font-size: 1.5rem;
    }

    .content-body p {
        font-size: 0.9rem;
    }

    .content-background-color p {
        font-size: 0.9rem;
    }

    .content-box .content-title h2 {
        font-size: 1.5rem;
    }

    .content-container .content-box {
        height: min-content;
    }
    .content-container .content-box.icon {
        height: auto;
    }
}

.banner {
    display: flex;
    width: 100%;
    height: 2rem;
    margin-top: -2rem;
    align-items: center;
    justify-content: center;
    background-color: var(--main);
}

.banner {
    overflow: hidden;
    white-space: nowrap;
}

.banner-container {
    display: flex;
    flex-direction: row;
}

.banner-container h3 {
    font-size: 1.2rem;
}