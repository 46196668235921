
.accordion-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; 
    padding: 0.5rem 1.5rem;
}

.accordion-item {
    width: 100%;
    border-radius: 0.4rem;
    margin-bottom: 1rem;
    border: rem(1px) solid light-dark(var(--background-layer-3), var(--background-layer-2));
    background-color: var(--background-layer-2);
    box-shadow: 0 0 5px 0.2rem rgba(0,0,0,0.15);
}

.accordion-title {
    color: var(--main);
    font-family: Paytone One;
    background-color: var(--background-layer-2);
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
    cursor: pointer;
    border-radius: 0.4rem;
    padding: 1rem 1.25rem;
}

.accordion-title svg {
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    transition: color 0.2s ease;
}

.accordion-title:hover svg {
    color: var(--main);
}

.accordion-title h2 {
    font-size: 1.25rem;
    text-align: left;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

.accordion-body {
    border-radius: 0.4rem;
    background-color: var(--background-layer-2);
    font-family: Nunito;
    overflow: hidden;
    display: grid;
    grid-template-rows: 0fr;
    transition: background-color 1s, grid-template-rows 0.5s ease-in-out;
}

.accordion-body > *  {
    overflow: hidden;
    background-color: var(--background-layer-2);
    padding: 0 1rem;
    transition: background-color 1s, grid-template-rows 0.5s, padding 0.5s ease-in-out;
}

.accordion-body.active {
    background-color: var(--background-layer-3);
    grid-template-rows: 1fr;
}

.accordion-body.active > * {
    background-color: var(--background-layer-3);
    padding: 1rem;
}

.accordion-body ul {
    list-style-type: disc; 
    padding-left: 1rem; 
    margin: 0.5rem 0;
}

.accordion-body li p {
    padding: 0.5rem 0;
}
.accordion-body li p {
    color: var(--main);
    margin-left: -0.5rem;
    font-size: 1.1rem;
}

.accordion-body li::marker {
    color: transparent;
}

@media screen and (max-width: 600px) {
    
    .accordion-title h2 {
        font-size: 1.1rem;
    }
    .accordion-body p {
        font-size: 0.9rem;
    }
}