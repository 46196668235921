
nav {
  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0rem 1rem 0rem 0rem;
  min-height: var(--nav-height);
  z-index: 100;
  background-color: var(--background-layer-1);
  box-shadow: 0.2rem 0.2rem 0rem rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid white;
  width: 100%;
  transition: background-color 0.5s ease;
}

nav svg {
  position: relative;
  top: 0.5rem;
  fill: white;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  transition: fill 0.5s ease;
  display: inline-block;
}

nav svg:hover {
  fill: #333;
}

nav.sticky {
  border-style: solid;
  border-left: transparent;
  border-right: transparent;
  border-top: transparent;
}

#menu {
  display: flex;
}

#menu ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  transition: all 0.5s ease-in-out;
}

#navbar-logo-container {
  position: absolute;
  left: calc(50% - 90px);
  bottom: -40px;
  transition: all 0.5s ease-in-out;
}

.panel-main-navbar #navbar-logo-container {
  position: absolute;
  left: calc(50% - 90px + 68px);
}

#navbar-logo {
  width: 180px;
  cursor: pointer;
  z-index: 100;
  transition: all 0.5s ease-in-out;
}

.sticky #navbar-logo-container {
  scale: 0.9;
  transition: all 0.5s ease-in-out;
}

.nav-links li #sign-in-button {
  background-color: var(--main);
  color: white;
  display: inline-block;
  font-family: Nunito;
  padding: 0.5rem 0.85rem;
  cursor: pointer;
  border-radius: 0.75rem;
  font-size: 1.1rem;
  border: var(--main) 2px solid;
  transition: all 0.2s ease;
}

.nav-links li #sign-in-button:hover {
  background-color: white;
  color: var(--main);
}

.nav-button.worker {
  position: absolute;
  left: 1rem;
  top: calc(var(--nav-height) - 3rem);
}

.nav-button.admin {
  position: absolute;
  left: 8.5rem;
  top: calc(var(--nav-height) - 3rem);
}



.nav-links {
  list-style: none;
  text-align: center;
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
}

.nav-links li:first-child {
  margin-left: 0;
}


#nav-icon {
  height: 52px;
  background-position: center;
  z-index: 1000;
  object-fit: contain;
  border-radius: 50%;
  transition: border 0.5s ease;
  cursor: pointer;
  padding: 2px;
  background-color: var(--background-layer-3);
  transition: all 0.2s ease;
}

#nav-icon:hover {
  background-color: var(--background-layer-4);
}

#nav-icon-selected {
  height: 52px;
  background-position: center;
  z-index: 1000;
  object-fit: contain;
  border-radius: 50%;
  transition: border 0.5s ease;
  cursor: pointer;
  padding: 2px;
  transition: all 0.2s ease;
  background-color: var(--background-layer-4);
}

.nav-links li .nav-button {
  width: fit-content;
  font-size: 0.95rem;
  font-family: Nunito;
  color: white;
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  border-radius: 0.15rem;
  background-color: transparent;
  border: none;
  position: relative;
  text-decoration: none;
  border-bottom: 1px solid transparent; /* Transparent border initially */
  transition: all 0.2s ease; /* Transition for border width */
}

.nav-links li .nav-button:hover {
  cursor: pointer;
  color: var(--main);
}

.nav-links li .nav-button::before,
.nav-links li .nav-button::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 1px;
  background-color: var(--main); /* Change color as needed */
  transition: width 0.35s ease;
}

.nav-links li .nav-button::before {
  transform: translateX(-50%);
}

.nav-links li .nav-button::after {
  transform: translateX(-50%);
}

.nav-links li .nav-button:hover::before {
  width: 50%;
}

.nav-links li .nav-button:hover::after {
  width: 60%;
}

.burger {
  display: none;
  cursor: pointer;
}

#menu-burger-media {
  padding: 1rem;
  display: none;
}

@media screen and (max-width: 1400px) {
  
}

@media screen and (max-width: 1170px) {
  .burger {
    display: block;
  }
  #menu-burger-media {
    display: none;
  }
  #menu-burger-media.active {
    display: block;
    z-index: 1000;
  }
  #menu {
    display: none;
  }
  #menu-burger-media:not(.active) {
    transform: translateX(20rem);
    transition: transform 0.7s ease-in;
  }
  #menu-burger-media.active {
    transform: translateX(0);
    transition: transform 0.7s ease-out;
  }
  #menu-burger-media {
    height: 100vh;
    top: 0;
    right: 0;
    background-color: #333;
    position: fixed;
  }
  .nav-links li {
    white-space: nowrap;
    width: 100%;
    text-align: left;
    margin-left: 0;
    display: block;
  }
  .nav-button {
    width: 100%;
    text-align: left;
    display: block;
  }
  .nav-links {
    flex-direction: column;
    justify-content: center;
  }
  nav {
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 0.9rem;
  }
}

/*

PROFILE MODAL

*/

.profile-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: calc(var(--nav-height) + 1rem);
  right: 1rem;
}

.profile-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--background-layer-2);
  border-radius: var(--border-radius-4);
  gap: 0.5rem;
  border: var(--background-layer-4) 1px solid;
  width: 100%;
  height: 100%;
  padding: 1rem;
}


.profile-modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--lighter);
  gap: 0.25rem;
  padding-bottom: 0.25rem;
}

.profile-modal-header p {
  font-size: 0.95rem;
  color: var(--lighter);
  margin: 0;
  padding: 0;
} 

#profile-pic {
  height: 60px;
  width: 67.27px;
  border-radius: 50%;
  object-fit: cover;
  background-color: var(--background-layer-4);
  padding: 0.25rem;
}

.profile-picture-username {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.profile-picture-username h2 {
  font-size: 1.5rem;
  text-align: center;
  margin: 0;
  padding: 0;
  user-select: none;
}


.profile-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.profile-modal-body-buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  gap: 0.25rem;
}

.profile-modal-body-button {
  font-family: Nunito;
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-start;
  justify-self: stretch;
  outline: none;
  border: none;
  background-color: transparent;
  color: white;
  text-decoration: none;
  padding:0 0 0 0.25rem;
}

.profile-modal-body-button:hover {
  cursor: pointer;
  color: var(--light);
}

/*

PROFILE MODAL

*/

/*

SIGN UP

*/









/*
signup form 
*/

.logo-img {
  width: 240px;
  height: 240px;
}

.exit-icon {
  position:absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
  transition: 0.5s;
}

.exit-icon:hover {
  transform: scale(1.2);
  color: var(--dark);
}

.signup-form {
  text-align: center;
  width: 18rem;
  font-size: 1rem;
  padding: 1rem;
  background-color: white;
  color: black;
  border-radius: var(--border-radius-2);
  user-select: none;
}

.signup-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 0.5rem;
}


.signup-footer button {
  font-family: Nunito;
  text-align: center;
  cursor: pointer;
  min-width: 7rem;
}

.signup-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  gap: 0.5rem;
}

.auth-login {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  gap: 0.5rem;
  border: 0;
  border-radius: 2rem;
  border-style: solid;
  transition: 0.5s;
}

.auth-provider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.5rem;
  background-color: var(--main);
  border: 2px solid var(--dark);
  border-radius: 2rem;
  cursor: pointer;
  transition: 0.5s;
}

.auth-provider:hover {
  background-color: var(--light);
}

.auth-provider:active {
  background-color: var(--lighter);
}

.auth-provider svg {
  color: white;
}

.standard-btn {
  background-color: var(--main);
  border: 2px solid var(--dark);
  font-family: Nunito;
  color: white;
  font-weight: 800;
  height: 2.5rem;
  border-radius: 2rem;
  width: 100%;
  transition: 0.5s ease;
}

.standard-btn:hover {
  background-color: var(--light);
  cursor: pointer;
}

.standard-btn:active {
  background-color: var(--lighter);
}

.signup-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 0.5rem;
}