
.order-details.coaching {
    margin-top: 2rem;
}

.coaching-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;
    flex-grow: 1;
    max-width: 44rem;
}

.coaching.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;
    flex-grow: 1;
    max-width: 44rem;
}