
.blog {
    display: flex;
    flex-direction: column;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    padding: 1rem;
    width: 100%;
    max-width: 1400px;
    margin-top: var(--nav-height);
    min-height: 100vh;
}

.blog .selected-title {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 2rem 1rem 2rem;
    box-shadow: inset 0 0 1rem rgba(0, 0, 0, 0.5);
    background-color: var(--background-layer-2);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border: 1px solid var(--main);
    gap: 0.5rem;
}

.blog .selected-title h1 {
    font-family: Nunito;
    font-weight: 800;
    font-size: 2rem;
    color: var(--main);
    text-align: left;
}

.blog .selected-title p {
    font-size: 1.1rem;
    text-align: left;
    margin-left: 1rem;
}

.post img {
    max-width: 418px;
    border-radius: 1rem;
    border-bottom-left-radius: 0.1rem;
    border-bottom-right-radius: 0.1rem;
}

.blog .content .posts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.posts .post {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    width: 418px;
    height: 463px;
    background-color: var(--background-layer-2);
    border-radius: 1rem;
    transition: background-color 0.1s;
    text-decoration: none;
    color: white;
    margin: 0.5rem;
    border: 1px solid var(--background-layer-1);
}

.posts .post .title {
    font-family: Nunito;
    font-size: 1.25rem;
    text-align: left;
    color: white;
}

.posts .post .description, .posts .post .date {
    padding: 0 1rem;
}

.posts .post:hover {
    background-color: var(--background-layer-3);
}


.web-title h1 {
    font-family: Nunito;
    font-weight: 600;
    font-size: 4rem;
    text-align: left;
}

.web-title {
    margin-top: 4rem;
    margin-bottom: 5rem;
    text-align: center;
}

.post .tags {
    gap: 0.5rem;
    display: flex;
    flex-direction: row;
    overflow: auto;
    width: 386px;
    padding-bottom: 0.5rem;
}

.post span {
    font-size: 0.9rem;
    font-family: Nunito;
    background-color: var(--main);
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    text-wrap: nowrap;
}

.post .tags::-webkit-scrollbar {
    cursor: grab;
}

.post .tags::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1rem rgba(0, 0, 0, 0.25); 
    cursor: grab;
}

.post .tags::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 1rem rgb(74, 74, 74); 
    cursor: grab;
}

.post .date {
    font-size: 0.9rem;
    color: var(--main);
    width: 100%;
    text-align: left;
}

.post .description {
    font-size: 1rem;
    width: 100%;
    text-align: left;
    max-height: 4.25rem;
    overflow: hidden;
}

.post .read-more {
    position:absolute;
    bottom: 0;
    text-align: center;
    font-size: 0.9rem;
    color: var(--main);
    padding: 0.5rem;
    transition: background-color 0.1s;
}

.post .read-more:hover {
    color: var(--lighter);
}


