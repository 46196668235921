

.terms-of-service {
  margin: 0;
  padding: 2rem;
}

.terms-of-service h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.terms-of-service h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.terms-of-service h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.terms-of-service ul {
    margin-left: 2rem;
}

.terms-of-service li {
    margin-bottom: 0.5rem;
    margin-left: -3rem;
}

.terms-of-service li::marker {
    color: transparent;
}

.terms-of-service li p strong {
    color: var(--main);
}

.terms-of-service p {
    margin-left: 1rem;
}

.terms-of-service-footer {
    margin-top: 2rem;
}

.terms-of-service-footer p {
    margin-left: 1rem;
}

.terms-of-service-footer select {
  font-family: Nunito, sans-serif;
  padding: 0.25rem 0.5rem;
  border-radius: 0.2rem;
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  margin-top: 0.5rem;
  margin-left: 1.5rem;
}