
footer {
    display: block;
}

.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1a1a1a;
    color: #fff;
    gap: 0.5rem;
    padding: 1rem 3rem;
}

.footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

#footer-logo {
    width: 180px;
}

.footer-content a:hover {
    color: var(--main);
}

.footer-content a {
    font-family: Nunito;
    font-size: 0.9rem;
    color: white;
    position: relative;
    width: fit-content;
    padding: 0 0.5rem;
    border: none;
    text-decoration: none;
    border-bottom: 1px solid transparent; /* Transparent border initially */
    transition: all 0.2s ease; /* Transition for border width */
}

.footer-content a:hover {
    cursor: pointer;
    color: var(--main);
}

.footer-content a::before,
.footer-content a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 1px;
    background-color: var(--main); /* Change color as needed */
    transition: width 0.35s ease;
}

.footer-content a::before {
    transform: translateX(-50%);
}

.footer-content a ::after {
    transform: translateX(-50%);
}

.footer-content a:hover::before {
    width: 70%;
}

.footer-content a:hover::after {
    width: 30%;
}

.footer-item h3 {
    color: var(--main);
}

.footer-item a.disabled {
    color: #fff;
    opacity: 0.5;
    cursor: default;
}

.footer-item a.disabled:hover {
    color: #fff;
}

.footer-item a.disabled::before,
.footer-item a.disabled::after,
.footer-item .social-media a::before,
.footer-item .social-media a::after {   
    width: 0;
}

.footer-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.footer-items {
    display: flex;
    flex-direction: row;
    width: calc(100% - 180px);
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 4rem;
    gap: 1rem;
}

.footer-items .footer-item {
    min-width: 15rem;
}

.footer-item.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: fit-content;
    gap: 0;
}

.social-media {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:space-between;
}

.social-media a:hover svg {
    color: var(--main);
}

.social-media svg {
    color: #fff;
    text-decoration: none;
}

.footer-divider {
    background-color: #fff;
    width: 90%;
    border:none;
    height: 1.5px;
    margin: 1rem 0;
}

.footer-copyright-disclaimer {
    display: flex;
    width: 85%;
    flex-direction: column;
    text-align: center;
    background-color: #1a1a1a;
    gap: 0.5rem;
    color: #fff;
}

.footer-copyright-disclaimer p {
    font-size: 0.85rem;
}

@media screen and (max-width: 1137px) {
        
            .footer-content {
                flex-direction: column;
                align-items: center;
                gap: 1rem;
            }
        
            .footer-items {
                padding: 0;
                width: 100%;
            }
        
            .footer-items .footer-item {
                margin: 0;
                padding: 0;
                min-width: 0;
            }
        
            .footer-item.logo {
                align-items: center;
                width: 100%;
            }
        
            .social-media {
                max-width: 100%;
                justify-content: center;
            }
        
            .footer-divider {
                width: 100%;
            }
        
            .footer-container {
                padding: 1rem 0;
            }
        
}

@media screen and (max-width: 600px) {
        
            .footer-content {
                flex-direction: column;
                align-items: center;
                gap: 1rem;
            }
        
            .footer-items {
                padding: 0;
                width: 100%;
            }
        
            .footer-items .footer-item {
                width: 100%;
            }
        
            .footer-item.logo {
                align-items: center;
                width: 100%;
            }
        
            .social-media {
                max-width: 100%;
                justify-content: center;
            }
        
            .footer-divider {
                width: 100%;
            }
        
            .footer-container {
                padding: 1rem 0;
            }
    
}


@media screen and (max-width: 1300px) {
    
        .footer-container {
            padding: 1rem 2rem;
        }

        .footer-divider {
            width: 85%;
        }

        .footer-copyright-disclaimer {
            width: 80%;
        }
    
}