

.break-word {
    overflow-wrap: break-word;
    word-wrap: break-word; 
    word-break: break-all;
}

.rank-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--background-layer-3);
    border-radius: var(--border-radius-3);
    padding: 1rem;
    align-self: stretch;
    flex: 1;
    max-width: 25rem;
    text-align: center;
}

.progress-bar {
    width: 100%;
    height: 100%;
}

.progress-bar-label {
    font-size: 1.2rem;
    font-weight: 800;
    color: white;
    margin: 0.5rem;
}

/*

ACCOUNT INFORMATION

*/

.account-information {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--background-layer-2);
    border-radius: var(--border-radius-2);
    padding: 1rem;
    width: 100%;
    flex-grow: 1;
    gap: 0.5rem;
}

.account-information .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    padding: 1rem;
    gap: 0.5rem;
    background-color: var(--background-layer-3);
    border-radius: var(--border-radius-3);
}

.account-information .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 0.5rem;
    text-align: center;
}

.account-information .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 0.5rem;
    align-self: stretch;
    flex-grow: 1;
    flex-basis: 0;
}

.account-information .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    background-color: var(--background-layer-3);
    border-radius: var(--border-radius-3);
    padding: 1rem;
    flex-grow: 1;
    gap: 0.5rem;
}
.account-information .card input {
    background-color: var(--background-layer-4);
    border-radius: var(--border-radius-4);
    border: none;
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 800;
    color: white;
    width: 100%;
}

.account-information .card input::placeholder {
    padding-left: 0.25rem;
}

.account-information .card input:focus {
    outline: none;
}

.account-information .big-btn {
    font-size: 1.5rem;
    flex-grow: 1;
    align-self: stretch;
    flex-basis: 0;
    color: white;
    background-color: var(--main);
    border: none;
    border-radius: var(--border-radius-3);
    font-family: Paytone One;
    transition: 0.2s all ease-in-out;
    padding: 0.5rem;
}

.account-information .big-btn:hover {
    cursor: pointer;
    background-color: white;
    color: var(--main);
    transition: 0.2s all ease-in-out;
}

/*

BOOSTS

*/

.header h1 {
    text-align: center;
}


/*

PENDING BOOSTS

*/

.pending-order {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    align-self: stretch;
    flex-grow: 1;
    flex-wrap: wrap;    
    background-color: var(--background-layer-2);
    border-radius: var(--border-radius-2);
} 

.pending-order .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    background-color: var(--background-layer-3);
    border-radius: var(--border-radius-3);
    margin: 0.5rem;
}

.pending-order .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 0.5rem;
}

.pending-order.division-boost .header {
    width: 36.5rem;
}

.pending-order.placement-games .header {
    width: 25rem;
}

.pending-order.normal-games .header, .pending.net-wins .header {
    width: 22rem;
}

.pending-order.coaching {
    flex-direction: column;
}

.pending-order.coaching .wrapper {
    flex-grow: 1;
}

.pending-order .header .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    gap: 0.5rem;
}

.pending-order.net-wins .header .container {
    min-width: calc(50%);
}

.pending-order .header .container .rank-card {
    background-color: var(--background-layer-4);
}

.pending-order .header .container .container {
    display: flex;
    flex-direction: column;
    background-color: var(--background-layer-4);
    border-radius: var(--border-radius-4);
    padding: 1rem;
    align-self: stretch;
    flex:1;
    white-space: nowrap;
}

.coaching .header .container .container {
    max-width: 25rem;
}

.container .divider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    align-self: stretch;
    flex-wrap: wrap;
    min-width: 100%;
    flex:1;
}

.pending-order .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-grow: 1;
    margin: 0.5rem;
    flex-basis: 0;
    gap: 0.5rem;
}

.pending-order .body .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-wrap: wrap;
    gap: 0.5rem;
    flex-grow: 1;
}

.pending-order .body .container .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    padding: 1rem;
    align-self: stretch;
    gap: 0.5rem;
    background-color: var(--background-layer-3);
    border-radius: var(--border-radius-3);
    max-width: 100%; /* Set the maximum width to 100% */
    
    /* Allow the text to break within words if it overflows the container's size 
    overflow-wrap: break-word;
    word-wrap: break-word; 
    word-break: break-all;
    */
    box-sizing: border-box; /* Include padding and border in the element's total width */
}


.pending-order .body .container .card.row {
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

#pending-accept {
    align-self: stretch;
    background-color: var(--main);
    border-radius: var(--border-radius-3);
    color: white;
    font-size: 1.5rem;
    font-family: Paytone One;
    border: 0;
    padding: 1rem;
    flex-grow: 1;
    flex-basis: 0;
    transition: all 0.2s ease-in-out;
}

#pending-accept:hover {
    cursor: pointer;
    background-color: white;
    color: var(--main);
}


@media screen and (max-width: 1250px) {
    .pending-order.normal-games .wrapper {
        flex-grow: 1;
    }

    .pending-order.division-boost .header {
        max-width: 36.5rem;
    }
    
    .pending-order.division-boost {
        flex-direction: column;
    }

    .pending-order.normal-games .header {
        max-width: 100%;
        width: 100%;
    }
}


@media screen and (max-width: 1000px) {
    .pending-order.net-wins {
        flex-direction: column;
    }
}


/*

PENDING BOOSTS

*/


/*

ACTIVE DIVISION BOOSTING

*/

.active-order {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--background-layer-2);
    border-radius: var(--border-radius-2);
    padding: 1rem;
    width: 100%;
    flex-grow: 1;
    gap: 1rem;
}

.active-order .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    align-self: stretch;
    padding: 1rem;
    gap: 0.5rem;
    background-color: var(--background-layer-3);
    border-radius: var(--border-radius-3);
}

.active-order .rank-card {
    background-color: var(--background-layer-4);
    border-radius: var(--border-radius-4);
}

.active-order .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-grow: 1;
    align-self: stretch;
    gap: 0.5rem;
}

.active-order.normal-games .header .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    gap: 0.5rem;
    flex-basis: 0;
}


.active-order.normal-games .container .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: var(--background-layer-4);
    border-radius: var(--border-radius-4);
    flex-grow: 1;
    align-self: stretch;
    padding: 1rem;
    gap: 0.5rem;
    min-width: 18.5rem;
}

.active-order.net-wins .container .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: var(--background-layer-4);
    border-radius: var(--border-radius-4);
    flex-grow: 1;
    padding: 1rem;
    align-self: stretch;
    flex-basis: 0;
    min-width: 17rem;
    max-width: 25rem;
    gap: 0.5rem;
}

.active-order.net-wins .rank-card {
    min-width: 17rem;
}

.active-order.coaching .container .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: var(--background-layer-4);
    border-radius: var(--border-radius-4);
    flex-grow: 1;
    padding: 1rem;
    align-self: stretch;
    flex-basis: 0;
    min-width: 17rem;
    gap: 0.5rem;
}

.active-order .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 0.5rem;
}

.active-order.coaching .body .container {
    flex-direction: column;
    align-self: stretch;
}

.active-order .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    background-color: var(--background-layer-3);
    border-radius: var(--border-radius-3);
    padding: 1rem;
    flex-grow: 1;
    gap: 0.5rem;
}

.buttons.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-self: stretch;
    flex-grow: 1;
}

.card .buttons.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-self: stretch;
}

.massive-btn {
    font-size: 2rem;
    flex-grow: 1;
    flex-basis: 0;
    align-self: stretch;
    padding: 1rem;
    color: white;
    background-color: var(--main);
    border: none;
    border-radius: var(--border-radius-3);
    font-family: Paytone One;
    transition: 0.2s all ease-in-out;
}

.massive-btn:hover {
    cursor: pointer;
    background-color: white;
    color: var(--main);
    transition: 0.2s all ease-in-out;
}

.rank-updater {
    position: absolute;
    top: -25vh;
    left: calc(50%  + 4.1875rem - 10rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    gap: 0.5rem;
    width: 20rem;
    background-color: var(--background-layer-3);
    border-radius: var(--border-radius-3);
    animation: anvil 0.5s ease-in-out;
}

.rank-updater-close {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
    color: white;
    font-size: 1rem;
    transition: all 0.2s ease-in-out;
}

.rank-updater-close:hover {
    cursor: pointer;
    color: var(--main);
    transition: all 0.2s ease-in-out;
}

.rank-updater-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    align-self:stretch;
    gap: 0.5rem;
}

.rank-updater-update {
    background-color: var(--main);
    color: white;
    font-weight: 800;
    font-size: 1rem;
    flex-grow: 1;
    height: 3rem;
    border: none;
    border-radius: 1rem;
    flex-basis: 0;
}

.rank-updater-cancel {
    color: var(--main);
    font-size: 1rem;
    font-weight: 800;
    flex-grow: 1;
    height: 3rem;
    border: none;
    border-radius: 1rem;
    flex-basis: 0;
}

.rank-updater-cancel:hover {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-color: var(--main);
    color: white;
}

.rank-updater-update:hover {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-color: white;
    color: var(--main);
}

.boost-conclude {
    position: absolute;
    top: -20vh;
    left: calc(50%  + 4.1875rem - 12rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    width: 24rem;
    gap: 0.5rem;
    background-color: var(--background-layer-3);
    border-radius: var(--border-radius-3);
    animation: anvil 0.5s ease-in-out;
}

.separator {
    width: 80%;
}

.update-order {
    position: absolute;
    top: -20vh;
    left: calc(50%  + 4.1875rem - 15rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 0.5rem;
    padding: 1rem;
    width: 30rem;
    flex-grow: 1;
    background-color: var(--background-layer-3);
    border-radius: var(--border-radius-3);
    animation: anvil 0.5s ease-in-out;
}

/*

ACCOUNT INFORMATION

*/

.update-order.account-information {
    top: -35vh;
}

.account-information-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-grow: 1;
    gap: 0.5rem;
}

.account-information-card {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: var(--background-layer-4);
    border-radius: var(--border-radius-4);
    padding: 1rem;
    width: 100%;
    flex-grow: 1;
}

.messages-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-grow: 1;
}

.messages-panel .container-header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    text-align: center;
    color: var(--main);
    background-color: var(--background-layer-3);
    border-top-left-radius: var(--border-radius-3);
    border-top-right-radius: var(--border-radius-3);
    padding: 1rem;
    border-bottom: solid 2px var(--main);
}

.messages {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-grow: 1;
}

.messages-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    align-self: stretch;
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: calc(100vh - var(--nav-height) - 6.875rem - 3rem);
    background-color: var(--background-layer-1);
    padding: 0.25rem 0.25rem 0.25rem 0rem;
    gap: 0.25rem;
}

.messages-header::-webkit-scrollbar {
    width: 0.35rem;
}

.messages-header::-webkit-scrollbar-track {
    background-color: var(--background-layer-3);
}

.messages-header::-webkit-scrollbar-thumb {
    background-color: var(--background-layer-4);
    border-radius: var(--border-radius-3);
}

.messages-header .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border: transparent 1px solid;
    border-radius: 0.25rem;
    background-color: var(--background-layer-2);
    transition: all 0.2s ease-in-out;
}

.messages-header .container:hover {
    cursor: pointer;
    background-color: var(--background-layer-3);
}

.messages-header .container.selected {
    background-color: var(--background-layer-3);
    border: solid 1px var(--darker);
}

.messages-header .container.unread {
    animation: 3s unread-messages infinite;
}

.messages-header .container .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    gap: 0.25rem;
}

.messages-header .container .card .card-header h1 {
    color: var(--main);
    font-size: 1rem;
}

.messages-header .container .card .card-header p {
    color: var(--main);
}

.messages-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    align-self: stretch;
    border-right: var(--background-layer-2) 2px solid;
    border-left: var(--background-layer-2) 1px solid;
}

.messages-body .container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: stretch;
    padding: 1rem;
    gap: 0.15rem;
    overflow-y: auto;
    max-height: calc(100vh - var(--nav-height) - 5.188rem - 6.875rem - 3rem);
    flex-direction: column-reverse;
}

.messages-body .container::-webkit-scrollbar {
    width: 0.5rem;
}

.messages-body .container::-webkit-scrollbar-track {
    background-color: var(--background-layer-2);
    border-radius: var(--border-radius-3);
}

.messages-body .container::-webkit-scrollbar-thumb {
    background-color: var(--background-layer-3);
    border-radius: var(--border-radius-3);
}

.messages-body .container .card {
    display: flex;
    flex-direction: column;
}

.messages-body .container .card .card-header {
    padding: 0.5rem;
    width: fit-content;
}

.messages-body .container .card.sender .card-header {
    color: white;
}

.messages-body .container .card.sendee .card-header {
    color: var(--main);
}

.messages-body .container .card.sender {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.messages-body .container .card.sender .card-body p {
    width: fit-content;
    padding: 0.5rem;
    background-color: var(--main);
    border-radius: 0.5rem;
    
    max-width: 100%; /* Set the maximum width to 100% */
    
    /* Allow the text to break within words if it overflows the container's size */
    overflow-wrap: break-word;
    word-wrap: break-word; /* For broader browser support */
    word-break: break-all;
    box-sizing: border-box; /* Include padding and border in the element's total width */
}

.messages-body .container .card.sendee {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.messages-body .container .card.sendee .card-body p {
    width: fit-content;
    padding: 0.5rem;
    background-color: var(--background-layer-2);
    border-radius: 0.5rem;
    
    max-width: 100%; /* Set the maximum width to 100% */
    
    /* Allow the text to break within words if it overflows the container's size */
    overflow-wrap: break-word;
    word-wrap: break-word; /* For broader browser support */
    word-break: break-all;
    box-sizing: border-box; /* Include padding and border in the element's total width */
}

.input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    background-color: var(--background-layer-2);
    padding: 1rem;
    gap: 0.5rem;
}

.messages-body #messages-input {
    background-color: var(--background-layer-3);
    border: none;
    padding: 1rem;
    font-size: 1rem;
    color: white;
    width: 100%;
}

.messages-body #messages-input:focus {
    outline: none;
}

.messages-body #messages-input::placeholder {
    padding-left: 0.25rem;
}

.messages-body #messages-button {
    background-color: var(--main);
    border: none;
    color: white;
    font-size: 1rem;
    flex-grow: 1;
    border: none;
    border-radius: 2rem;
    padding: 1rem;
    transition: all 0.2s ease-in-out;
}

.messages-body #messages-button:hover {
    cursor: pointer;
    background-color: white;
    color: var(--main);
}

.messages-body #messages-button:disabled {
    background-color: transparent;
    cursor: not-allowed;
    animation: cooldown 5s linear forwards; /* Add animation when disabled */
}

@keyframes cooldown {
    0% {
        box-shadow: 0 0 0 0 var(--main) inset;
    }
    25% {
        box-shadow: 0 0 0 4px var(--dark) inset;
    }
    50% {
        box-shadow: 0 0 0 2px var(--main) inset;
    }
    75% {
        box-shadow: 0 0 0 4px var(--dark) inset;
    }
    100% {
        box-shadow: 0 0 0 2px var(--main) inset;
    }
}

#load-msg-btn {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1rem;
    border: none;
    padding: 0.5rem;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

#load-msg-btn:hover {
    color: var(--main);
}

#customPrice {
    background-color:white;
    border-radius: var(--border-radius-4);
    border: var(--background-layer-1) 1px solid;
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 800;
    color: var(--main);
    width: 100%;
}

@media screen and (max-width: 925px) {
    .rank-updater {
        left: calc(50% - 160px);
    }
    .boost-conclude {
        left: calc(50% - 190px);
    }
    .messages-header .container .card {
        padding: 0.1rem;
    }
}