
.order-now {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    min-height: calc(100vh - var(--nav-height));
}

.background-img {
    position: fixed;
    z-index: -1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.order-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 2;
    gap: 1rem;
    padding: 1rem;
}

.order-details.coaching h1 {
    background-color: var(--background-layer-2);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
}

.order-details-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    flex-grow: 1;
    align-self: stretch;
}

.nav-children {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.order-details-content .nav-container {
    flex-grow: 1;
}

@media screen and (max-width: 435px) {
    .order-details-content .nav-container {
        flex-wrap: wrap;
        height: fit-content;
    }
    .division-boosting-addons .ordering-status-switch {
        width: 20rem;
    }
}

.order-details-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.order-now-checkout {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex-grow: 1;
}

#order-now-checkout-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: sticky;
    min-width: 24rem;
    max-width: 27.5rem;
    align-self: stretch;
    background-color: var(--background-layer-2);
    border-radius: 1rem;
    gap: 0.5rem;
    transition: all 0.5s ease;
}

.order-now-checkout-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.order-now-checkout-content table {
    width: 100%;
    font-family: Nunito;
}

.order-now-checkout-content table tr .left {
    text-align: left;
    margin: 0;
}

.order-now-checkout-content table tr .left.strong {
    font-size: 1.2rem;
    font-weight: 800;
    color: var(--main);
}

.order-now-checkout-content table tr .right {
    font-weight: 800;
    color: var(--main);
    text-align: right;
    font-style: italic;
    margin: 0;
}

.order-now-checkout-discount {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 0.5rem;
    padding: 0rem 2rem;
    width: 100%;
}

.order-now-checkout-discount-applied {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    padding-left: 1rem;
    background-color: var(--main);
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

.order-now-checkout-discount-applied #discount-code-btn {
    background-color: white;
    color: var(--main);
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    border: none;
    font-size: 1rem;
    transition: background-color 0.5s;
}

.order-now-checkout-apply-discount {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: white;
    align-items: center;
    gap: 1rem;
    padding-left: 0.5rem;
    width: 14rem;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

.order-now-checkout-apply-discount.shake {
    animation: horizontal-shaking 0.5s ease-in-out infinite;
}

.order-now-checkout-apply-discount #discount-code-btn {
    background-color: var(--main);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    border: none;
    font-size: 1rem;
    transition: background-color 0.5s;
}

.order-now-checkout-apply-discount #discount-code-btn:hover {
    background-color: var(--main);
    color: white;
    font-weight: 800;
    cursor: pointer;
}

#discount-code-input {
    background-color: white;
    color: var(--main);
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    border: none;
    font-size: 0.8rem;
    transition: background-color 0.5s;
    width: 100%
}

#discount-code-input:focus {
    outline: none;
}

#discount-code-input:focus div {
    border: 1px solid var(--main);
}


#discount-code-btn {
    background-color: var(--main);
    color: white;
    padding: 0.75rem 1rem;
    border-radius: 2rem;
    border: none;
    font-size: 1rem;
    transition: background-color 0.5s;
}

#discount-code-btn:hover {
    background-color: white;
    color: var(--main);
    font-weight: 800;
    cursor: pointer;
}

.order-now-checkout-total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.order-now-checkout-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

#checkout-button {
    font-family: Paytone One;
    background-color: var(--main);
    padding: 0.5rem 2rem;
    color: white;
    font-size: 2rem !important;
    border-radius: 3rem;
    border: none;
    font-size: 1rem;
    border: 2px solid var(--dark);
    cursor: pointer;
    transition: all 0.2s ease;
}

#checkout-button:hover {
    background-color: white;
    color: var(--main);
    border: 2px solid var(--main);
}

#stripe-logo {
    width: 128px;
    height: 29px;
    user-select: none;
}

@media screen and (max-width: 1155px) {
    #order-now-checkout-component {
        max-width: 44rem;
        left: calc(50% - (44rem/2));
    }
}


@media screen and (max-width: 500px) {
    #order-now-checkout-component {
        min-width: 22rem;
    }
}