

.hero {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: overlay;
    background-position: 95% 10%;
    min-height: calc(100vh - var(--nav-height));
}

.hero-content {
    position: absolute;
    top: 30%;
    left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    z-index: 1;
}

.hero-content-overlay {
    padding: 1rem;
    width: 34rem;
    border-radius: 0.5rem;
    background-color: #02062673;
}


.hero-content p {
    margin: 0;
}

.hero-content .main-title {
    font-size: 3rem;
    font-family: Paytone One;
}
.hero-content .sub-title {
    font-size: 2.5rem;
    font-family: Paytone One;
    color: var(--main);
}

.hero-content-buttons {
    display: flex;
    gap: 1rem;
    flex-grow: 1;
    align-self: stretch;
}

.hero-button {
    flex-grow: 1;
    align-self: stretch;
    flex-basis: 0;
    border-radius: 0.5rem;
    background-color: #020626;
    color: var(--main);
    font-size: 1.5rem;
    font-family: Paytone One;
    border: none;
    padding: 1rem;
    cursor: pointer;
    transition: 0.2s ease;
}

.hero-button:hover {
    background-color: var(--main);
    color: white;
}

.hero-content-overlay h1 {
    line-height: 1.25;
}


@media screen and (max-width: 900px) {
    .hero {
        background-position: 55% 10%;
    }
    .hero-content {
        top: 35%;
        left: 5%;
    }
    .hero-content-overlay {
        max-width: 28rem;
    }
    .hero-content-overlay h1 {
        line-height: 1;
    }
    .hero-content .main-title {
        font-size: 2.5rem;
    }
    .hero-content .sub-title {
        font-size: 1.8rem;
    }
    .hero-button {
        font-size: 1.5rem;
    }
}


@media screen and (max-width: 550px) {
    .hero-content {
        top: 35%;
        left: 0;
        padding: 1rem;
    }
    .hero-content-overlay {
        width: fit-content;
    }
    .hero-content-overlay h1 {
        line-height: 0.75;
    }
    .hero-content .main-title {
        font-size: 1.5rem;
    }
    .hero-content .sub-title {
        font-size: 1.25rem;
    }
    .hero-content p {
        font-size: 0.8rem;
    }
    .hero-content-buttons {
        gap: 0.5rem;
        flex-wrap: wrap;
    }
    .hero-button {
        font-size: 1.15rem;
    }
}