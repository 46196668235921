
.division-boosting-selectors {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-self: stretch;
    gap: 1rem;
}

.division-boosting-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background-color: var(--background-layer-2);
    border-radius: var(--border-radius-3);
    align-self: stretch;
    min-width: calc(50% - 0.5rem);
}

.division-boosting-selector p {
    margin: 0;
    color: var(--main);
}

.rank-selector {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    border-radius: var(--border-radius-3);
    background-color: var(--background-layer-2);
    flex-grow: 1;
    align-self: stretch;
    min-width: calc(50% - 0.5rem);
    gap: 0.5rem;
}

.division-boosting-ranks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    gap: 1rem;
    flex-grow: 1;
    max-width: 44rem;
}

.rank-selector {
    color: var(--main);
}

.rank-selector.current .selector-container {
    flex-grow: 0;
}

.rank-selector.previous {
    min-width: 0;
}

.rank-selector.previous .component-wrapper {
    background-color: transparent;
}

/*

ADDONS

*/

.ordering-status-switch {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 0.5rem;
    background-color: var(--background-layer-2);
    border: 1px solid var(--main);
    border-radius: var(--border-radius-3);
    height: 3rem;
    width: 20.75rem;
    justify-content: space-between;
}

.ordering-status-icons-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ordering-status-switch p {
    margin: 0;
    font-size: 0.75rem;
    position: left;
    text-align: left;
    align-self: center;
}

.ordering-status-icon {
    color: var(--main);
    position: relative;
    left: 0.5rem;
    transition: all 0.5s ease-in-out;
}

.ordering-status-switch button {
    margin-left: 0.5rem;
    border: none;
    font-size: 1rem;
    transition: all 0.5s;
    background-color: transparent;
    color: var(--main);
    font-weight: 800;
    text-align: left;
}

.switch-container {
    background-color: var(--background-layer-4);
    width: 5rem;
    margin-left: 0.5rem;
    border-radius: 1rem;
    border: 1px solid var(--main);
    cursor: pointer;
}

.checkout-addon-off {
    font-family: Nunito;
    background-color: white;
    color: var(--main);
    transition: all 0.5s ease-in-out;
    border-radius: 1rem;
    height: 2rem;
    width: 3rem;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
}

.checkout-addon-on {
    font-family: Nunito;
    background-color: var(--main);
    color: white;
    transition: all 0.5s ease-in-out;
    translate: 2rem;
    border-radius: 1rem;
    height: 2rem;
    width: 3rem;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
}

.checkout-addon-active {
    translate: 1rem 0.2rem;
}

/*

ADDONS
    
*/

.division-boosting-addons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: var(--background-layer-2);
    border-radius: var(--border-radius-3);
    flex-grow: 1;
    max-width: 44rem;
}

.ordering-status-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-items: center;
    gap: 0.5rem;
}

.placements {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-grow: 1; 
    align-self: stretch;
    max-width: 44rem;
}

.placements-game-number {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: var(--background-layer-2);
    border-radius: var(--border-radius-3);
    flex-grow: 1;
    align-self: stretch;
}

@media screen and (max-width: 600px) {
    .placements {
        flex-direction: column;
    }
}