

.privacy-policy {
  margin: 0;
  padding: 2rem;
}

.privacy-policy h1 {
  font-size: 2.5rem;
  margin-top: 1rem;
  color: var(--main);
}

.privacy-policy h2 {
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
  margin-top: 0.75rem;
  color: var(--lighter);
}

.privacy-policy h3 {
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
}

.privacy-policy p {
    margin-left: 1rem;
    margin-bottom: 0.25rem;
}

.privacy-policy a {
  color: var(--main);
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}