
.panel {
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - var(--nav-height));
    width: 100%;
}

.panel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    width: 100%;
    flex-grow: 1;
    gap: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.panel-navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: center;
    padding: 1.25rem;
    gap: 1.25rem;
    background-color: var(--background-layer-2);
    border-top-right-radius: 1rem;
}


.panel-navbar-icon-text, .panel-navbar-icon-text-active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: var(--border-radius-4);
    gap: 1rem;
    cursor: pointer;
}

.panel-navbar-icon-text {
    background-color: var(--background-layer-3);
    transition: all 0.2s ease-in-out;
}

.panel-navbar-icon-text.unread {
    animation: unread-messages 3s infinite;
}

.panel-navbar-icon-text:hover {
    background-color: var(--background-layer-4);
    transition: all 0.2s ease-in-out;
}

.panel-navbar-icon-text:hover .panel-navbar-icon,
.panel-navbar-icon-text:hover .panel-navbar-text {
    color: var(--logo-color);
    transition: all 0.2s ease-in-out;
}

.panel-navbar-icon-text-active {
    background-color: var(--background-layer-4);
    color: var(--logo-color);
    transition: all 0.2s ease-in-out;
}

.panel-navbar-icon-text-active .panel-navbar-icon,
.panel-navbar-icon-text-active .panel-navbar-text {
    color: var(--logo-color);
    transition: all 0.2s ease-in-out;
}

.panel-navbar-icon {
    position: relative;
    top: 0.8rem;
    width: 1.5rem;
    height: 1.5rem;
    color: white;
}

.panel-navbar-text {
    position: relative;
    padding-top: 0.2rem;
    color: white;
    font-size: 0.8rem;
    font-weight: 400;
}

.flex-dropdown button {
    width: 20rem;
    align-items: center;
    justify-content: center;
    display: flex;
}

.flex-dropdown-content {
    width: 20rem;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.flex-dropdown-content button:hover {
    background-color: var(--logo-color);
    transition: 0.5s;
}



/* 
CSS for specific panels
*/

.specific-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    width: 100%;
    height: 100%;
}

.specific-panel-navbar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    border-radius: 2rem;
    background-color: var(--background-layer-2);
    margin: 1rem;
}

.specific-panel-navbar-button, .specific-panel-navbar-button-active {
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    width: 8rem;
    height: 4rem;
    border-radius: 2rem;
    border: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.specific-panel-navbar-button {
    color: white;
    background-color: var(--background-layer-2);
}

.specific-panel-navbar-button-active {
    color: var(--logo-color);
    background-color: var(--background-layer-3);
}

.specific-panel-navbar-button:hover {
    background-color: var(--background-layer-3);
    color: var(--logo-color);
    transition: all 0.2s ease-in-out;
}



@media screen and (max-width: 925px) {
    #navbar-logo {
        width: 160px;
        height: 71.11px;
    }
    #navbar-logo-container {
        left: calc(50% - 80px);
    }
    .panel-main-navbar #navbar-logo {
        width: 120px;
        height: 53.33px;
    }
    .panel-main-navbar #navbar-logo-container {
        bottom: -30px;
    }
    .panel-main-navbar #navbar-logo-container {
        left: calc(50% - 60px);
    }
    .panel-navbar {
        flex-direction: row;
        justify-content: center;
        gap: 1rem;
        padding: 2rem;
        padding-bottom: 1rem;
        background-color: var(--background-layer-1);
        overflow: auto;

    }
    .panel-navbar-text {
        display: none;
    }
    .panel-navbar-icon-text, .panel-navbar-icon-text-active {
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;

    }
    .panel-navbar svg {
        position: inherit;
    }
    .panel-container {
        margin-top: 0;
    }
    .panel {
        flex-direction: column;
    }
}