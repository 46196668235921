
/*

INPUT FIELD

*/

.input-field {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 2rem;
    background-color: transparent;
    border: 2px solid var(--dark);
    transition: all 0.5s ease-in-out;
}

.input-field input {
    border-radius: 2rem;
}

.input-field:not(.icon) input {
    text-align: center;
}

.input-field:not(.icon) input:focus::placeholder {
    color: transparent;
}

.input-field input::-webkit-outer-spin-button, .input-field input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.input-field.icon svg {
    padding: 0.5rem;
    transition: all 0.5s ease-in-out;
}

.input-field.icon {
    padding: 0rem 0.5rem;
}

.input-field input {
    border: 0;
    padding: 0.5rem;
    text-align: left;
    width: 100%;
    transition: all 0.5s ease-in-out;
    height: 2.5rem;
    margin: 0;
}

.input-field input::placeholder {
    padding-left: 0.1rem;
}

.input-field.error, .input-field.error input, .input-field.error svg, .input-field.icon.error.focus svg {
    color: var(--error);
}


.input-field input:focus {
    outline: none;
}

/*

INPUT FIELD

*/



/*

TABLE

*/

.table-container::-webkit-scrollbar {
    width: 1rem;
}

.table-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1rem rgba(0, 0, 0, 0.25); 
    border-radius: 0.5rem;
}

.table-container::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    -webkit-box-shadow: inset 0 0 1rem rgba(255,255,255,1); 
}

.table-container {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    flex-basis: 0;
    background-color: var(--background-layer-2);
}

.table-container.selected .table-element {
    background-color: var(--main);
    color: white;
}

.table {
    width: 100%;
    text-align: center;
    border-radius: var(--border-radius-3);
}

.table-body {
    border-radius: var(--border-radius-3);
}

.table-container {
    border-radius: var(--border-radius-3);
}

.table-body tr:hover .table-element {
    color: white;
    background-color: var(--main);
    user-select: none;
    cursor: pointer;
}

.table-body tr .table-element {
    transition: all 0.1s ease-in-out;
}

.table-header-element {
    color: white;
    user-select: none;
    background-color: var(--background-layer-3);
    border-radius: 0.25rem;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
}

.table-element {
    border-radius: var(--border-radius-3);
    background-color: var(--background-layer-5);
    color: white;
    padding: 0.5rem 1rem;
}

.table-element p {
    margin: 0;
}

/*

TABLE

*/


/*

NAV BUTTON

*/


.panel-container .nav-button.active, .nav-container button {
    background-color: var(--background-layer-3);
}

.panel-container .nav-button.inactive {
    background-color: var(--background-layer-2);
}

.nav-button.active, .nav-button.inactive, .nav-container button {
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    font-family: Nunito;
    height: 100%;
    cursor: pointer;
    border-radius: 2rem;
    font-size: 1rem;
    color:white;
    text-align: center;
    flex-grow: 1;
    align-self:stretch;
}
.nav-container button {
    height: 4.05rem;
}

.order-details-content .nav-button.active, .nav-container button {
    background-color: var(--background-layer-1);
}

.nav-button.active, .nav-container button {
    background-color: var(--background-layer-3);
    color: var(--main);
    cursor: default;
}

.nav-button.active, .nav-container button {
    background-color: var(--background-layer-1);
    color: var(--main);
    cursor: default;
}

.nav-container.tall .nav-button.active {
    background-color: var(--background-layer-2);
}

.nav-button.inactive {
    background-color: var(--background-layer-2);
}

.nav-button.inactive:hover {
    color: var(--main);
}

/*

NAV BUTTON

*/



/*

NAV CONTAINER

*/
.nav-container.tall {
    display: flex;
    flex-direction: column;
}

.nav-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background-color: var(--background-layer-2);
    border-radius: 2rem;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.2);
}

.nav-container.admin {
    margin-top: 1.5rem;
}

/*

NAV CONTAINER

*/


/*

LOADING

*/

.loading-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.loading {
    position: absolute;
    top: 40%;
    left: 50%;
    border: 8px solid #f3f3f3;
    border-top: 8px solid var(--main);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

.loading.centered {
    left: calc(50% + 40px);
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/*

LOADING

*/



/*

DROPDOWN

*/
.dropdown {
    position:relative;
    display: inline-block;
    position: center;
    user-select: none;
}

.dropdown svg {
    position: absolute;
    right: 0.75rem;
    top: calc(50% - 0.5rem);
    width: 0.75rem;
    color: white;
    cursor: pointer;
    transition: all 0.35s ease-in-out;
}

.dropdown.clicked svg {
    transform: rotate(180deg);
}

.dropdown button {
    display: inline-block;
    font-family: Nunito;
    padding: 0.5rem;
    color: white;
    font-weight: 800;
    background-color: var(--main);
    font-size: 1rem;
    width: 8rem;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: transparent solid 1px;
    transition: all 0.1s ease-in-out;
}

.dropdown.wide button {
    width: 9.5rem;
}

.dropdown.inactive button {
    border-radius: 2rem;
    transition: all 0.5s ease-in-out;
    transition-delay: 0.35s;
}

.dropdown.clicked button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    border-bottom: var(--light) solid 1px;
    transition-delay: 0;
}

.dropdown-content.wide {
    width: 9.5rem;
}

.dropdown-content {
    display: grid;
    font-size: 1rem;
    width: 8rem;
    position: absolute;
    text-align: center;
    overflow: hidden; /* Changed from auto to hidden */
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.4);
    z-index: 1;
    transition: max-height 0.5s ease-out; /* Change to transition max-height */
    max-height: 0; /* Initially set max-height to 0 */
    transition-delay: 0s;
}

.dropdown-content.clicked {
    max-height: 100vh; /* Change max-height to 100vh when clicked */
    transition: max-height 1s ease-out; /* Change to transition max-height */
    transition-delay: 0.175s;
}

.dropdown-element {
    border: transparent solid 1px;
    display: block;
    background-color: white;
    color: black;
    padding: 5px;
    text-decoration: none;
}

.dropdown-element:hover {
    color: white;
    font-weight: 800;
    background-color: var(--main);
    cursor: pointer;
}

.dropdown-element.active {
    color: white;
    font-weight: 800;
    background-color: var(--main);
}

.dropdown-element.disabled {
    display: none;
}

.dropdown-element.disabled:hover {
    border-bottom: transparent solid 1px;
    border-top: transparent solid 1px;
}

/*

DROPDOWN

*/



/*

SELECTOR BUTTON 
    
*/

.selector-button {
    padding: 0.75rem;
    border-radius: 2rem;
    border: none;
    cursor: pointer;
    min-width: 2.65rem;
    color: white;
    border: transparent solid 1px;
}

.selector-button.inactive {
    background-color: white;
    color: black;
}

.selector-button.active {
    background-color: var(--main);
    cursor: default;
}

.selector-button.disabled {
    background-color: var(--background-layer-1);
    cursor: default;
    user-select: none;
}

.selector-button.disabled:hover {
    color: white;
}

.selector-button.inactive.disabled {
    background-color: var(--background-layer-1);
    color: white;
    cursor: default;
    user-select: none;
}

.selector-button.inactive.disabled:hover {
    color: white;
    background-color: var(--background-layer-1);
}

.selector-button.inactive:hover {
    background-color: var(--main);
    color: white;
}

/*

SELECTOR BUTTON 
    
*/



/*

SELECTOR CONTAINER

*/

.selector-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    flex-grow: 1;
    flex-wrap: wrap;
}

/*

SELECTOR CONTAINER

*/


/*

ROLES

*/

.roles-container {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    justify-content: flex-start;
    gap: 0.5rem;
    flex-wrap: wrap;
}

/*

ROLES

*/

/*

CHAMPIONS

*/


.champions-container {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    justify-content: flex-start;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.champions-container p {
    padding: 0.5rem;
    border-radius: 0.25rem;
    background-color: var(--dark);
    cursor: default;
    white-space: nowrap;
    max-width: 15rem;
    text-align: center;
    flex: 1;
}


/*

ADDONS

*/

.addons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.5rem;
    flex-wrap: wrap;
    align-self: stretch;
}

.addon-details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: var(--dark);
    border-radius: var(--border-radius-3);
    padding: 0.5rem;
    cursor: default;
    flex:1;
    white-space: nowrap;
    max-width: 35rem;
}

.addon-icon {
    width: 1.3rem;
    height: 1.3rem;
    color: white;
}

/*

ADDONS

*/


/*

SPECIFIC ROLES

*/

.role-selector-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    align-self: stretch;
    gap: 0.5rem;
    background-color: var(--background-layer-2);
    border-radius: var(--border-radius-3);
    box-shadow: inset var(--background-layer-1) 0 0 25px 0;
}

.role-selector {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    border-radius: var(--border-radius-3);
}

.role-selector #role {
    border-radius: 0.75rem;
    cursor: pointer;
    z-index: 2;
}

.role-effect.selected  {    
    box-shadow: 0 0 20px 25px var(--dark);
}

.role-effect {
    position:absolute;
    transition: all 0.2s ease-in-out;
    z-index: 1;
}

.role-selector .container:hover .role-effect {
    box-shadow: 0 0 50px 25px var(--dark);
}

.role-selector .container:hover .role-effect.selected {
    box-shadow: 0 0 20px 25px var(--dark);
}

.role-selector .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}



/*

SPECIFIC ROLES

*/


/*

SPECIFIC CHAMPIONS

*/

.champions-selector-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    align-self: stretch;
    gap: 0.5rem;
    min-height: 25rem;
    background-color: var(--background-layer-2);
    border-radius: var(--border-radius-3);
    box-shadow: inset var(--background-layer-1) 0 0 25px 0;
}

.champions-selector-container h2 {
    text-align: center;
}

.champions-search {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
}

.champions-search input {
    border-radius: 0.75rem;
    padding: 0.5rem 1rem;
    border: transparent solid 1px;
    background-color: var(--background-layer-3);
    color: white;
}

.champions-search input:focus {
    outline: none;
}

.champions-search button {
    border-radius: 0.75rem;
    padding: 0.5rem;
    border: transparent solid 1px;
    background-color: var(--background-layer-3);
    color: white;
    cursor: pointer;
}

.champions-search button:hover {
    background-color: var(--dark);
}

.champion-selector-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 16rem;
}

.champion-selector-wrapper.selected {
    height: 5.5rem;
}

.champions-selector-container hr {
    width: 100%;
    border: var(--main) solid 0.5px;
}

.champion-selector {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0.5rem;
    flex-wrap: wrap;
    gap: 0.5rem;
    border-radius: var(--border-radius-3);
    overflow: auto;
}


.coaching-selectors {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-self: stretch;
    gap: 1rem;
}

.coaching-selector {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    background-color: var(--background-layer-2);
    border-radius: var(--border-radius-3);
    min-width: calc(50% - 0.5rem);
    align-self: stretch;
}

.champion-selector p {
    padding: 0.5rem;
    border-radius: 0.25rem;
    background-color: var(--background-layer-3);
    flex: 1;
    white-space: nowrap;
    max-width: 10rem;
    text-align: center;
}

.champion-selector p:hover {
    background-color: var(--dark);
    cursor: pointer;
}

.champion-selector p.selected {
    background-color: var(--main);
    color: white;
}

.champion-selector::-webkit-scrollbar {
    width: 0.5rem;
}

.champion-selector::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1rem rgba(0, 0, 0, 0.25); 
    border-radius: 0.5rem;
}

.champion-selector::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    -webkit-box-shadow: inset 0 0 1rem rgba(255,255,255,1); 
}


.selected-champions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.selected-champions p {
    padding: 0.5rem;
    border-radius: 0.25rem;
    background-color: var(--background-layer-3);
}

/*

PAGE SELECTOR

*/

.page-selector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: calc(100% - 168px);
    padding: 0.5rem;
    border-radius: var(--border-radius-3);
    background-color: var(--background-layer-2);
}

@media screen and (max-width: 925px) {
    .page-selector {
        width: calc(100% - 50px);
    }
    .table-header-element {
        font-size: 1rem;
    }
    .table-element {
        font-size: 0.9rem;
    }

}   

.page-selector-text {
    display: flex;
    gap: 0.25rem;
}

.page-selector-button {
    padding: 0.5rem;
    border-radius: var(--border-radius-3);
    background-color: var(--background-layer-3);
    color: white;
    cursor: pointer;
    border: none;
}

.page-selector-button:hover {
    color: var(--main);
}

.page-selector-input {
    padding: 0.5rem;
    border-radius: var(--border-radius-3);
    background-color: var(--background-layer-3);
    color: white;
    border: none;
    width: 4rem;
    text-align: center;
}


/*

SEARCH FIELDS

*/

.search-field {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 2rem;
    background-color: var(--background-layer-2);
}

.search-bar {
    position:absolute;
    right: 1rem;
    top: calc(1rem + var(--nav-height));
}

.search-field input {
    border-radius: 2rem;
    background-color: transparent;
    border: transparent solid 1px;
    color: white;
    padding: 0.5rem;
    max-width: 6rem;
}

.search-field input:focus {
    outline: none;
}

#search-field-button {
    border-radius: 2rem;
    background-color: var(--background-layer-3);
    border: transparent solid 1px;
    color: white;
    cursor: pointer;
    align-self: stretch;
}

#search-field-button:hover {
    background-color: var(--background-layer-4);
}

.nav-bar-search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: 2rem;
    align-self: stretch;
}

@media screen and (max-width: 925px) {
    .nav-bar-search .search-bar {
        display: none;
    }
    .nav-bar-search .nav-container.admin {
        position: relative;
        width: 100%;
        overflow: auto;
        padding-left: 10rem;
        border-radius: 5rem;
        margin-top: 0;
    }
}